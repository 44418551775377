<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">{{ $lang.labels.password }}</div>
      <h2 class="text-h3 text-lg-h2 mb-6">{{ $lang.labels.setPassword }}</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text>
        <v-text-field
          v-model="formData.password"
          type="password"
          :label="$lang.labels.password"
          outlined
          hide-details
          data-cy="password"
          @keyup.enter="submit()"
        ></v-text-field>
        <v-text-field
          v-model="formData.confirmPassword"
          class="mt-2"
          type="password"
          :label="$lang.labels.confirmPassword"
          outlined
          hide-details
          data-cy="confirmPassword"
          @keyup.enter="submit()"
        ></v-text-field>
        <v-btn
          block
          class="accent mt-4 color-accent-text"
          x-large
          :disabled="loadingLogin || tokenMissing"
          :loading="loadingLogin"
          data-cy="btn-submit"
          @click="submit()"
        >{{ $lang.labels.setPassword }}</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import {
  setUserPasswordUsingPOST as setUserPassword
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      tokenMissing: false,
      loadingLogin: false,
      formData: {
        confirmPassword: '',
        password: '',
        registerToken: ''
      }
    }
  },
  created() {
    if (this.$route && this.$route.params && this.$route.params.token) {
      this.formData.registerToken = this.$route.params.token
    } else {
      this.addSnackbar({
        message: this.$lang.errors.tokenMissing,
        timeout: 5000,
        color: 'error'
      })
      this.tokenMissing = true
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    submit() {
      this.loadingLogin = true
      // Check if Password is the same
      if (this.formData.password.length === 0) {
        this.addSnackbar({
          message: this.$lang.errors.missingPassword,
          timeout: 5000,
          color: 'error'
        })
        this.loadingLogin = false

        return
      }
      if (this.formData.password !== this.formData.confirmPassword) {
        this.addSnackbar({
          message: this.$lang.errors.passwordNotSame,
          timeout: 5000,
          color: 'error'
        })
        this.loadingLogin = false

        return
      }

      // Authenticate user
      setUserPassword({ body: this.formData })
        .then((res) => {
          if (res?.data?.status === 'SUCCESS') {

            this.loadingLogin = false
            this.addSnackbar({
              message: this.$lang.success.passwordSet,
              timeout: 5000,
              color: 'success'
            })
            setTimeout(() => {
              window.open('/','_self')
            }, 2000)

            return
          }
          this.loadingLogin = false
          if (res?.response?.status === 404) {
            this.addSnackbar({
              message: `${this.$lang.errors.somethingWentWrong} ${this.$lang.errors.maybeLinkWasExpired}`,
              timeout: 5000,
              color: 'error'
            })

            return
          }
          const errorMessage = res?.response?.data?.statusText ? res.response.data.statusText : this.$lang.errors.somethingWentWrong

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
        })
        .catch(() => {
          this.addSnackbar({
            message: this.$lang.errors.somethingWentWrong,
            timeout: 5000,
            color: 'error'
          })
          this.loadingLogin = false
        })

    }
  }
}
</script>
